import { confirmDelete } from '@/libs/decorator';
import { mixins } from 'vue-class-component';
import { Component } from 'vue-property-decorator';
import { namespace } from 'vuex-class';
import DictMixin from './dict-mixin';
const System = namespace('System');
@Component
export default class BaseMixin extends mixins(DictMixin) {
  @System.Mutation removeActiveTabs: any;
  @System.Mutation removeTabsWithoutConfirm: any;
  @System.Mutation closeFormTabs: any;
  @System.Mutation replaceActiveTabs: any;
  @System.State activeTabs: any;
  spinning: boolean = false;

  onDeleteRemoveTab(type: string, id: number | string) {
    let path = this.$route.fullPath;
    this.activeTabs.forEach((v: any) => {
      if (v.fullPath.includes(type + '/edit?id=' + id) || v.fullPath.includes(type + '/detail?id=' + id)) {
        if (path === v.fullPath) {
          let parent: string = v.fullPath
            .split('/')
            .filter((v: string, index: number) => index < 3)
            .join('/');
          if (parent === '/decision/indicator') {
            let query = this.$route.query;
            let { indSetCode } = query;
            parent += '?indSetCode=' + indSetCode;
          }
          if (parent === '/decision/rule') {
            let query = this.$route.query;
            let { ruleSetCode, ruleSetId } = query;
            parent += `?ruleSetCode=${ruleSetCode}&ruleSetId=${ruleSetId}`;
          }
          this.$router.push(parent);
        }
        this.removeTabsWithoutConfirm(v.fullPath);
      }
    });
  }

  replaceUrl(toPath: string) {
    let fromPath = this.$route.fullPath;
    if (!toPath) return false;
    if (fromPath == toPath) return false;
    this.replaceActiveTabs({ toPath, fromPath });
  }

  replaceUrlWithOutConfirm(toPath: string) {
    let fromPath = this.$route.fullPath;
    this.$router.replace(toPath);
    this.closeFormTabs(fromPath);
  }

  @confirmDelete('当前页面数据未保存，确定要离开？')
  replaceConfirm(key: string, path: string) {
    this.$router.replace(key);
    setTimeout(() => {
      this.removeActiveTabs(path);
    }, 100);
  }

  @confirmDelete('当前页面数据未保存，确定要离开？')
  onCancelRemoveTab() {
    let path: string = this.$route.fullPath;
    let parent: string = path
      .split('/')
      .filter((v: string, index: number) => index < 3)
      .join('/');
    if (parent === '/decision/indicator') {
      let query = this.$route.query;
      let { indSetCode } = query;
      parent += '?indSetCode=' + indSetCode;
    }
    if (parent === '/decision/rule') {
      let query = this.$route.query;
      let { ruleSetCode, ruleSetId } = query;
      parent += `?ruleSetCode=${ruleSetCode}&ruleSetId=${ruleSetId}`;
    }
    this.removeTabsWithoutConfirm(path);
    this.$router.push(parent);
  }
}

































import DictMixin from '@/mixins/dict-mixin';
import RoleServices from '@/services/role';
import PermisionServices from '@/services/permision';
import { mixins } from 'vue-class-component';
import { Component, Prop, PropSync, Watch } from 'vue-property-decorator';
@Component({
  components: {}
})
export default class AddUser extends mixins(DictMixin) {
  @PropSync('visible', { type: Boolean }) syncVisible!: boolean;
  @Prop() roleCode!: string;

  roleSrv = new RoleServices();
  perSrv = new PermisionServices();

  treeData: any = [];
  dataTree: any = [];
  checkedKeys: any = [];
  checkedDataCodes: any = {};
  selectedKeys: any[] = [];
  onCheckChange(selectedKeys: any, e: any) {
    this.selectedKeys = selectedKeys.concat(e.halfCheckedKeys);
  }

  created() {
    this.getList();
  }

  permissionList: any[] = [];
  async getList() {
    let list: any[] = await this.perSrv.getList();
    this.permissionList = list;
    let menu = list.filter((v: any) => v.permissionType === 0);
    this.treeData = menu.map((v: any) => {
      return {
        title: v.permissionName,
        key: v.permissionCode,
        children: list
          .filter((c: any) => c.parentPermissionCode === v.permissionCode)
          .map((sub: any) => ({
            title: sub.permissionName,
            key: sub.permissionCode,
            children: list
              .filter((x: any) => x.parentPermissionCode === sub.permissionCode)
              .map((thr: any) => ({
                title: thr.permissionName,
                key: thr.permissionCode,
                children: list.filter((z: any) => z.parentPermissionCode === thr.permissionCode).map((four: any) => ({ title: four.permissionName, key: four.permissionCode }))
              }))
          }))
      };
    });
    this.allChildNodes = [];
    this.getAllChildNodes(this.treeData);
    let data = list.filter((v: any) => v.permissionType === 3);
    let parent = data.filter((v: any) => v.parentPermissionCode === '');
    this.dataTree = parent.map((v: any) => {
      return {
        title: v.permissionName,
        key: v.permissionCode,
        children: data
          .filter((p: any) => p.parentPermissionCode === v.permissionCode)
          .map((c: any) => ({
            title: c.permissionName,
            key: c.permissionCode
          }))
      };
    });
  }

  allChildNodes: any[] = [];
  getAllChildNodes(data: any[]) {
    data.forEach(item => {
      if (item.children && item.children.length > 0) {
        this.getAllChildNodes(item.children);
      } else {
        this.allChildNodes.push(item.key);
      }
    });
  }

  @Watch('roleCode')
  onRoleCodeChange() {
    this.initChecked();
    this.initCheckedData();
  }
  async initChecked() {
    let list = await this.perSrv.getPermisionList({ roleCode_eq: this.roleCode });
    let allKeys = list.map((v: any) => v.permissionCode);
    this.selectedKeys = allKeys;
    this.checkedKeys = [];
    allKeys.forEach((v: any) => {
      if (this.allChildNodes.includes(v)) this.checkedKeys.push(v);
    });
  }
  async initCheckedData() {
    this.checkedDataCodes = {};
    let list = await this.perSrv.getDataPermisionList({ roleCode_eq: this.roleCode });
    list.forEach((v: any) => {
      this.checkedDataCodes[v.permissionCode] = v.dataPermissionCode;
    });
  }

  // 解决数据不回显BUG
  onCheckedDataChange(key: string, e: any) { 
    this.$set(this.checkedDataCodes, key, e.target.value);
  }

  loading: boolean = false;
  async confirm() {
    // 数据权限
    let roleDataPermissionList: any[] = [];
    Object.keys(this.checkedDataCodes).forEach((v: any) => {
      roleDataPermissionList.push({
        dataPermissionCode: this.checkedDataCodes[v],
        dataPermissionValue: this.checkedDataCodes[v].split('_').pop(),
        permissionCode: v,
        roleCode: this.roleCode
      });
    });
    // 功能权限
    let map: any = {};
    this.selectedKeys.forEach((v: any) => {
      let per = this.permissionList.find((p: any) => p.permissionCode === v);
      if (per) map[v] = per.permissionUri.split(',');
    });
    let param = {
      permMap: map,
      roleCode: this.roleCode
    };
    this.loading = true;
    let res = await this.perSrv.savePermision(param);
    let dataRes = await this.perSrv.saveDataPermision({ roleDataPermissionList, roleCode: this.roleCode });
    if (res && dataRes) {
      this.$message.success('配置成功');
      this.syncVisible = false;
    }
    this.loading = false;
  }

  bodyStyle = { backgroundColor: '#EEE' };
}

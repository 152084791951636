


















import { Component } from 'vue-property-decorator';
import BasePageMixin from '@/mixins/base-page-mixin';
import { mixins } from 'vue-class-component';
import AddRole from './add-role.vue';
import AddDeptRole from './add-dept-role.vue';
import SetPermision from './set-permision.vue';
import RoleServices, { RoleDto } from '@/services/role';
import DictMixin from '@/mixins/dict-mixin';
import { sleep } from '@/libs/util';

@Component({
  components: { AddRole, AddDeptRole, SetPermision }
})
export default class Permision extends mixins(BasePageMixin, DictMixin) {
  roleSrv = new RoleServices();

  menu: any = [
    {
      title: '提报角色授权',
      icon: 'fa-vcard',
      expand: true,
      type: 'folder',
      oprate: [
        { name: '新建角色', icon: 'fa-plus-circle', key: 'action:addRole/0' },
        { name: '配置部门固化角色', icon: 'fa-plus-square', key: 'action:addDeptRole/0' }
      ],
      children: []
    }
  ];

  created() {
    this.getAllRoles();
  }

  getAllRoles() {
    this.getRoles('0');
  }

  async getRoles(module: string) {
    let list2 = await this.roleSrv.getCategory(module);
    this.menu[+module].children = [];
    list2.forEach((v: any) => {
      if (!v.dept) {
        v.roles.forEach((role: RoleDto) => this.menu[+module].children.push(this.packMenu(role, module)));
      } else {
        this.packMenuDept(v, module);
      }
    });
  }

  async packMenuDept(item: any, module: string) {
    if (!this.DICT_BUSINESS_DEPT || !this.DICT_FINANCIAL_BUSINESS_DEPT) {
      await sleep(100);
      this.packMenuDept(item, module);
      return false;
    }
    let deptCode = module === '1' ? 'DICT_FINANCIAL_BUSINESS_DEPT' : 'DICT_BUSINESS_DEPT';
    this.menu[+module].children.push({
      title: this.DICT_VALUE(deptCode, item.dept),
      icon: 'fa-users',
      key: 'deptTitle',
      type: 'folder',
      oprate: false,
      expand: true,
      children: item.roles.map((role: RoleDto) => this.packMenu(role, module))
    });
  }

  roleCodeList: string[] = [];
  packMenu(role: RoleDto, module: string) {
    this.roleCodeList.push(role.roleCode || '');
    let TYPE = module === '1' ? 'FMR_' : 'DAS_';
    return {
      title: role.roleName,
      icon: 'fa-user',
      key: '/manage/permision/detail?id=' + role.id,
      oprate: [
        { name: '配置权限', permissionKey: TYPE + 'ROLE_CFG_FUNC', icon: 'fa-cogs', key: 'action:configPermision/' + role.roleCode },
        { name: '编辑角色', permissionKey: TYPE + 'ROLE_UPD', icon: 'fa-edit', key: 'action:editRole/' + role.id },
        { name: '删除角色', permissionKey: TYPE + 'ROLE_DEL', icon: 'fa-trash', key: 'action:removeRole/' + role.id }
      ]
    };
  }

  roleId: number = 0;
  module: any = '1';
  roleVisible: boolean = false;
  addRole(key: string) {
    this.roleId = 0;
    this.module = key.replace('action:addRole/', '');
    this.roleVisible = true;
  }
  editRole(key: string) {
    this.roleId = parseInt(key.replace('action:editRole/', ''));
    this.roleVisible = true;
  }
  async removeRole(key: string) {
    let id = parseInt(key.replace('action:removeRole/', ''));
    this.$confirm({
      title: '删除确认',
      content: '确定要删除选中角色吗？',
      okText: '确认',
      cancelText: '取消',
      onOk: async () => {
        let res = await this.roleSrv.remove(id);
        res && this.getAllRoles();
      }
    });
  }

  deptVisible: boolean = false;
  addDeptRole(key: string) {
    this.module = key.replace('action:addDeptRole/', '');
    this.deptVisible = true;
  }

  permisionVisible: boolean = false;
  roleCode: string = '';
  configPermision(key: string) {
    this.roleCode = key.replace('action:configPermision/', '');
    this.permisionVisible = true;
  }

  update(key: string) {
    if (/\/manage\/permision/.test(key)) this.$router.replace(key);
    if (/addRole/.test(key)) this.addRole(key);
    if (/addDeptRole/.test(key)) this.addDeptRole(key);
    if (/removeRole/.test(key)) this.removeRole(key);
    if (/editRole/.test(key)) this.editRole(key);
    if (/configPermision/.test(key)) this.configPermision(key);
  }
}

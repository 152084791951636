





































import { Vue, Component, Prop, PropSync, Watch } from 'vue-property-decorator';
import RoleServices, { RoleDto } from '@/services/role';
import { Mutation } from 'vuex-class';
import { checkCode } from '@/libs/util';
@Component
export default class AddRole extends Vue {
  @PropSync('visible', { type: Boolean }) syncVisible!: boolean;
  @Prop() roleId!: number;
  @Prop() module!: string;
  roleSrv = new RoleServices();
  title: string = '新建角色';

  @Watch('visible')
  async onVisibleChange() {
    if (this.roleId > 0) {
      this.title = '编辑角色';
      this.getInfo();
    } else {
      this.formData = {};
      this.title = '新建角色';
    }
  }

  async getInfo() {
    this.formData = await this.roleSrv.getInfo(this.roleId);
  }

  formData: RoleDto = {};
  loading: boolean = false;
  async submit() {
    const must: { field: 'roleCode' | 'roleName'; msessage: string }[] = [
      { field: 'roleCode', msessage: '角色编码不能为空' },
      { field: 'roleName', msessage: '角色名称不能为空' }
    ];
    for (let val of must) {
      if (!this.formData[val.field]) {
        this.$message.error(val.msessage);
        return false;
      }
    }
    if (!checkCode(this.formData.roleCode)) {
      this.$message.error('编码只能包含英文字母、数字、下划线');
      return false;
    }
    if (!this.roleId) {
      let code = 'ROLE_' + this.formData.roleCode;
      let exist = await this.roleSrv.getList({ roleCode_eq: code });
      if (exist.length > 0) {
        this.$message.error(`编码[${code}]已经存在`);
        return false;
      }
    }
    this.loading = true;
    if (this.roleId > 0) {
      let res = await this.roleSrv.update(this.formData);
      this.formData = {};
      this.HANDLE_RELOAD_ROLE_DETAIL();
      this.$emit('success', res);
    } else {
      let res = await this.roleSrv.add({ ...this.formData, roleModule: this.module, roleKind: '0' });
      this.formData = {};
      this.$emit('success', res);
    }
    this.syncVisible = false;
    this.loading = false;
  }

  close() {
    this.syncVisible = false;
    this.formData = {};
    this.$emit('success');
  }

  @Mutation HANDLE_RELOAD_ROLE_DETAIL: any;
}

import http from './axios';

export default class PermisionServices {
  private readonly list_api = '/v1/permission/list';
  private readonly menu_api = '/v1/permission/menu';
  private readonly list_permision_api = '/v1/rolepermission/list';
  private readonly list_data_permision_api = '/v1/roledatapermission/list';
  private readonly save_permision_api = '/v1/rolepermission/saveperms';
  private readonly save_data_permision_api = '/v1/roledatapermission/saveDataPerms';

  constructor() {}

  async getList(params?: object): Promise<any> {
    let res: any = await http.get(this.list_api, { params: params });
    return res.payload;
  }

  async getPermisionList(params?: object): Promise<any> {
    let res: any = await http.get(this.list_permision_api, { params: params });
    return res.payload;
  }

  async getDataPermisionList(params?: object): Promise<any> {
    let res: any = await http.get(this.list_data_permision_api, { params: params });
    return res.payload;
  }

  async savePermision(data: any): Promise<boolean> {
    let res: any = await http.post(this.save_permision_api, data);
    return res.header?.code == 200;
  }

  async saveDataPermision(data: any): Promise<boolean> {
    let res: any = await http.post(this.save_data_permision_api, data);
    return res.header?.code == 200;
  }
}

export function confirmDelete(message: string = '是否确认执行删除操作？', title: string = '提示', cancelFn = function() {}) {
  return function(target: any, name: string, descriptor: PropertyDescriptor) {
    const originFn = descriptor.value;
    descriptor.value = async function(...rest: any[]) {
      target.$confirm({
        title: title,
        content: message,
        okText: '确认',
        cancelText: '取消',
        onOk: async () => {
          originFn.apply(this, rest);
        },
        onCancel: async () => {
          cancelFn && cancelFn();
        }
      });
    };
  };
}

export const loging = function() {
  return function(target: any, name: string, descriptor: PropertyDescriptor) {
    const originFn = descriptor.value;
    descriptor.value = async function(...rest: any[]) {
      await originFn.apply(this, rest);
    };
  };
};

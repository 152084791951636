













import { Component, Prop, PropSync, Watch } from 'vue-property-decorator';
import RoleServices from '@/services/role';
import DictMixin from '@/mixins/dict-mixin';
import { mixins } from 'vue-class-component';
@Component
export default class AddRole extends mixins(DictMixin) {
  @PropSync('visible', { type: Boolean }) syncVisible!: boolean;
  @Prop() roleId!: number;
  @Prop() module!: string;
  @Prop() roleCodeList!: string[];
  roleSrv = new RoleServices();
  title: string = '配置部门固化角色';

  treeData: any = [];

  @Watch('visible')
  async onVisibleChange() {
    this.getInfo();
  }

  async getInfo() {
    this.treeData = [];
    let dept: any;
    if (this.module === '1') {
      dept = this.DICT_FINANCIAL_BUSINESS_DEPT;
    } else {
      dept = this.DICT_BUSINESS_DEPT;
    }
    dept.forEach((v: any) => {
      this.treeData.push({
        title: v.label,
        key: v.value,
        checkable: false,
        children: this.getChildren(v)
      });
    });
  }

  getChildren(dept: any) {
    return this.DICT_ROLE_PERMISSION.filter((v: any) => v.parentItemCode === `DICT_PLATFORM_MODULE_${this.module}`).map((v: any) => {
      let key = `ROLE_${dept.value}_${this.module}_${v.value}/${v.label}/${dept.label}/${dept.value}`;
      if (this.roleCodeList.includes(`ROLE_${dept.value}_${this.module}_${v.value}`)) {
        this.checkedKeys.push(key);
        return { title: v.label, key, disableCheckbox: true };
      }
      return { title: v.label, key };
    });
  }

  checkedKeys: any = [];
  checkKeys(checkedKeys: any) {
    this.checkedKeys = checkedKeys;
  }

  async submit() {
    let list: any = [];
    this.checkedKeys.forEach((v: string) => {
      if (/^ROLE_/.test(v)) {
        let [roleCode, roleName, dept, deptCode] = v.split('/');
        list.push({
          roleCode,
          roleName,
          roleModule: this.module,
          roleRefDept: dept,
          roleRefDeptCode: deptCode,
          roleDescription: dept + '-' + roleName,
          roleKind: '1'
        });
      }
    });
    let res = await this.roleSrv.addRoleList(this.module, list);
    if (res) {
      this.$message.success('操作成功');
      this.syncVisible = false;
      this.$emit('success');
    }
  }

  close() {
    this.syncVisible = false;
    this.$emit('success');
  }
}

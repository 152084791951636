import { mixins } from 'vue-class-component';
import { Component } from 'vue-property-decorator';
import BaseMixin from './base-mixin';
import DictMixin from './dict-mixin';
@Component
export default class BasePageMixin extends mixins(DictMixin, BaseMixin) {
  current = 0;
  detail: any = {};
  visible = false;
  form = true;
  folderId: any = 0;
}
